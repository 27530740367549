import React, { Component } from "react"
import { Link, navigate } from "gatsby"

import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"
import * as ROLES from "../../constants/roles"

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  company: "",
  phone: "",
  email: "",
  emailTwo: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null,
}

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use"

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const {
      firstName,
      lastName,
      company,
      phone,
      email,
      passwordOne,
      isAdmin,
    } = this.state
    const roles = {}

    let userInfo = {
      firstName: firstName,
      lastName: lastName,
      company: company,
      phone: phone,
      email: email,
    }

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).setWithPriority({
          firstName,
          lastName,
          company,
          phone,
          email,
          roles,
        }, this.props.firebase.timestamp())
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification()
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        fetch("/api/newUser", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userInfo: userInfo,
          }),
        })
        if (typeof this.props.redirect !== "undefined") {
          if (this.props.redirect === "") {
            navigate(ROUTES.ACCOUNT)
          } else {
            navigate("/" + this.props.redirect)
          }
        } else {
          navigate(ROUTES.ACCOUNT)
        }
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  render() {
    const {
      firstName,
      lastName,
      company,
      phone,
      email,
      emailTwo,
      passwordOne,
      passwordTwo,
      error,
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      email !== emailTwo ||
      firstName === "" ||
      lastName === "" ||
      company === "" ||
      phone === ""

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="firstName"
            placeholder="First name"
            className="form-control"
            value={firstName}
            onChange={this.onChange}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            name="lastName"
            placeholder="Last name"
            className="form-control"
            value={lastName}
            onChange={this.onChange}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            name="company"
            placeholder="Company"
            className="form-control"
            value={company}
            onChange={this.onChange}
          />
        </div>

        <div className="form-group">
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            className="form-control"
            value={phone}
            onChange={this.onChange}
          />
        </div>

        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="Email address"
            className="form-control"
            value={email}
            onChange={this.onChange}
          />
        </div>

        <div className="form-group">
          <input
            type="email"
            name="emailTwo"
            placeholder="Confirm Email"
            className="form-control"
            value={emailTwo}
            onChange={this.onChange}
          />
        </div>

        <div className="form-group">
          <input
            type="password"
            name="passwordOne"
            placeholder="Password"
            className="form-control"
            value={passwordOne}
            onChange={this.onChange}
          />
        </div>

        <div className="form-group">
          <input
            type="password"
            name="passwordTwo"
            placeholder="Confirm Password"
            className="form-control"
            value={passwordTwo}
            onChange={this.onChange}
          />
        </div>

        <button disabled={isInvalid} type="submit" className="default-btn">
          <i className="bx bxs-hot"></i>
          Sign Up
          <span></span>
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
)

export default withFirebase(SignUpFormBase)

export { SignUpLink }
