import React, { Fragment } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SignUpForm from "../components/SignUp"
import logo from "../images/logo-70px.png"


const SignUp = props => (
  <Fragment>
    <section className="signup-area">
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 pb-100">
          <div className="signup-content">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="signup-form">
                  <div className="logo">
                    <img src={logo} alt="logo" />
                  </div>

                  <h3>Create your HRS Systems Account now</h3>
                  <p>
                    If you are an existing customer please do not sign up using this form, contact us at hass@hrssystems.com
                  </p>
                  <p>
                    Already signed up? <Link to="/login">Log in</Link>
                  </p>
                  <SignUpForm redirect={props.redirect} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
)

function getRedirect(location) {
  if (location) {
    if (location.state) {
      return location.state.redirect ? location.state.redirect : ""
    } else {
      return ""
    }
  } else {
    return ""
  }
}

export default function SignUpPage({ location }) {
  return (
    <Layout title="Get Started" description="Get started with your HRS Systems account.">
      <SignUp redirect={getRedirect(location)} />
    </Layout>
  )
}
